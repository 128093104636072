import type DataObject from 'o365.modules.DataObject.ts';
import type { ItemModel, DataItemModel} from 'o365.modules.DataObject.Types.ts';

import localStorageHelper from 'o365.modules.StorageHelpers.ts';
import { LayoutGroupByModule } from 'o365.modules.DataObject.Layout.ts';

/**
 * Base group by defenition, should not be used directly, use one of the implemenations 
 */
export abstract class BaseGroupBy<T extends ItemModel = ItemModel> {
    protected _dataObject: DataObject<T>;
    protected _groupBy: Array<Array<GroupByDefinition>>;
    protected _storage: { [key: string]: GroupStorageItem };
    protected _trackLayout = false;
    /**
     * async function called after expand
     */
    protected _postExpandProcessHandler: (groupIndex: number, groupLoadedCount: number) => Promise<void>;
    /**
     * async function called after collapse
     */
    protected _postCollapseProcessHandler: (groupIndex: number, groupCollapsedCount: number) => Promise<void>;

    get groupBy() { return this._groupBy }

    protected enabled: boolean = false;
    updated: Date;

    constructor(options: { dataObject: DataObject<T> }) {
        this._dataObject = options.dataObject;
        this.updated = new Date();
        this._groupBy = [];
        this._storage = {};
    }

    //--- Abstract ---

    /**
   * Load all
   */
    abstract load(pParams: any): Promise<any>
    /**
     * Expand
     */
    abstract loadGroup(group: any, pParams: any, skipCheck: boolean): Promise<void>;
    /**
     * Collapse
     */
    abstract collapseDetails(item: any, index?: number): Promise<void>;

    /**
     * Expand all items
     */
    abstract expandAll(): Promise<any[]>;
    /**
     * Collapse all items
     */
    abstract collapseAll(): void;

    /** Layouts and other post create initializations */
    abstract postCreateInit(): void;

    /**
     *  
     */
    abstract setLastDetailFormatFunction(func: LastDetailFormatFunction): void;
    /** Enable storage and function overrides for group by */
    abstract enableOverrides(): void;
    /** Cleanup storage and function overrides for group by */
    abstract disableOverrides(): void;

    //--- Shared ---

    /**
     * Set the group by. Must be an array
     */
    setGroupBy(groupBy: Array<Array<string | GroupByDefinition>> | Array<string | GroupByDefinition> | null) {
        if (groupBy === null) {
            this._groupBy.splice(0, this._groupBy.length);
        } else {
            const wrapper = groupBy.some((x: any) => Array.isArray(x))
                ? groupBy
                : [groupBy];

            this._groupBy = wrapper.map((level: any) => level.map((definition: string | GroupByDefinition) => {
                if (typeof definition === 'string') {
                    return { name: definition };
                } else {
                    return definition;
                }
            }));
        }
        this._saveToLayout();
    }

    /**
     * Set the group by for a specific level
     */
    setGroupByForLevel(groupBy: Array<string | GroupByDefinition>, level: number) {
        if (level == null) { level = this._groupBy.length; }

        if (!Array.isArray(groupBy) || !(level >= 0 && level <= this._groupBy.length)) { return; }

        const group = [];

        groupBy.forEach((groupDefinition) => {
            let definition: GroupByDefinition;
            if (typeof groupDefinition === 'string') {
                definition = { name: groupDefinition };
            } else {
                definition = {
                    name: groupDefinition.name,
                    groupByAggregate: groupDefinition.groupByAggregate
                };
            }
            group.push(definition);
        });

        if (level === this._groupBy.length) {
            this._groupBy.push(group);
        } else {
            this._groupBy[level] = group;
        }

        this._saveToLayout();
    }

    /**
     * Add a field to the group level
     */
    addGroupToLevel(group: string | GroupByDefinition, level: number) {
        if (typeof group === 'string') {
            this._groupBy[level].push({ name: group });
        } else {
            this.groupBy[level].push(group);
        }
        this._saveToLayout();
    }

    /**
     * Remove a level from the group by
     */
    removeGroupByLevel(level: number) {
        if (!(level >= 0 && level < this._groupBy.length)) { return; }

        this._groupBy.splice(level, 1);
        this._saveToLayout();
    }

    /**
     * Remove a group field from a level
     */
    removeGroupFromLevel(fieldName: string, level: number) {
        if (!fieldName || !(level >= 0 && level < this._groupBy.length)) { return; }

        const index = this._groupBy[level].findIndex(x => x.name === fieldName);
        if (index === -1) { return; }

        this._groupBy[level].splice(index, 1);
        if (this._groupBy.length === 0) {
            this._groupBy.splice(level, 1);
        }
        this._saveToLayout();
    }

    protected _onStateChange?: (pEnabled: boolean) => void;
    onStateChange(pCallBack?: (enabled: boolean) => void) {
        this._onStateChange = pCallBack;
    }

    /**
     * Get the group key from a group up to a given level
     */
    protected getGroupByKey(group: GroupByExtendedDataItem, level: number) {
        const key = [];
        for (let i = 0; i <= level; i++) {
            const groupDefinition = this._groupBy[i];
            const levelKey = {};
            groupDefinition.forEach(definition => {
                if (definition.groupByAggregate) { return; }
                levelKey[definition.name] = group[definition.name];
            });
            key.push(levelKey);
        }
        return key;
    }

    /**
     * Find the array index of an item in dataobject data array
     */
    protected _getDataIndex(item: GroupByExtendedDataItem) {
        return this._dataObject.data.findIndex((x: any) => x.o_groupKey === item.o_groupKey);
    }

    protected _updateGroupByState() {
        if (this._groupBy.length === 0) {
            this.disableOverrides();
        } else {
            this.enableOverrides();
        }
    }

    /**
     * Set function that will be called after expand action
     */
    setPostExpandHandler(func: (groupIndex: number, groupLoadedCount: number) => Promise<any>) { this._postExpandProcessHandler = func; }
    /**
     * Set function that will be called after collapse action
     */
    setPostCollapseHandler(func: (groupIndex: number, collapsedDetailsCount: number) => Promise<any>) { this._postCollapseProcessHandler = func; }

    /**
     * Update virtual scroll lists
     */
    protected _update() {
        import('o365.vue.ts').then(x => {
            const ds = x.getDataObjectById(this._dataObject.id, this._dataObject.appId);
            if (ds?.groupBy) {
                ds.groupBy.updated = new Date();
            }
        });
        // window.requestAnimationFrame(() => {
        //     this.updated = new Date();
        // });
    }

    /** Save group by to active layout */
    protected _saveToLayout() {
        this._updateGroupByState();
        if (this._trackLayout && this._dataObject.layoutManager) {
            this._dataObject?.layoutManager?.saveLayout({
                includedModules: ['groupBy']
            });
        }
    }

    //------------------------------------------------------
    // LocalStorage expanded states
    /**
     * Get the local storage key for this group by
     */
    protected get _localKey() {
        return `${this._dataObject.id}_groupBy`
    }

    /**
     * Get the group states from local store for this group by
     */
    protected _getStoredStates(): { [key: string]: boolean } {
        try {
            const statesJsonString = localStorageHelper.getItem(this._localKey);
            if (!statesJsonString) { return {}; }
            const storedStates = JSON.parse(statesJsonString);
            return storedStates ?? {};
        } catch (ex) {
            console.error(ex);
            return {};
        }
    }

    /**
     * Store the item expanded state to local store
     */
    protected _storeItemState(item: GroupByExtendedDataItem, expanded: boolean) {
        if (!item) { return; }
        const storedStates = this._getStoredStates();
        const itemId = item.o_groupKey;
        if (expanded) {
            storedStates[itemId] = true;
        } else {
            delete storedStates[itemId];
        }
        if (Object.keys(storedStates).length === 0) {
            localStorageHelper.removeItem(this._localKey);
        } else {
            localStorageHelper.setItem(this._localKey, JSON.stringify(storedStates));
        }
    }
    //------------------------------------------------------
}

/**
 * Serverside implementation of GroupBy
 */
export default class GroupBy<T extends ItemModel = ItemModel> extends BaseGroupBy<T> {
    private _lastDetailFormatFunction?: LastDetailFormatFunction;

    isLoading: boolean;

    private _originalLoad: any;
    private _setupOptions: any;
    private _onDataLoadedCancel?: () => void;

    get groupBy() { return this._groupBy; }

    data: DataItemModel<T>[] = [];

    constructor(options: {
        dataObject: DataObject<T>
        lastDetailFormatFunction?: LastDetailFormatFunction,
        setupOptions: any
    }) {
        super({ dataObject: options.dataObject });
        this._dataObject = options.dataObject;
        this._lastDetailFormatFunction = options.lastDetailFormatFunction;
        this._groupBy = [];
        this._storage = {};
        this.updated = new Date();
        this.isLoading = false;

        this._originalLoad = this._dataObject.load;

        if (options.setupOptions) {
            if (options.setupOptions.initialGroupBy) {
                this.setGroupByForLevel(options.setupOptions.initialGroupBy, 0);
            }
            this._setupOptions = options.setupOptions;
        }
    }

    enableOverrides() {
        if (this.enabled) { return; }
        this.enabled = true;
        this._dataObject.storage.data.forEach(item => this.data.push(item));
        this._dataObject.setStoragePointer(this.data);
        this._dataObject.load = this.load.bind(this);
        this._onDataLoadedCancel = this._dataObject.on('DataLoaded', () => {
            this._update();
        });
        if (this._onStateChange) {
            this._onStateChange(this.enabled);
        }
    }
    disableOverrides() {
        if (!this.enabled) { return; }
        this.enabled = false;
        this._dataObject.load = this._originalLoad;
        this.data.splice(0, this.data.length);
        this._dataObject.setStoragePointer(undefined);
        if (this._onDataLoadedCancel) {
            this._onDataLoadedCancel();
            this._onDataLoadedCancel = undefined;
        }
        if (this._onStateChange) {
            this._onStateChange(this.enabled);
        }
    }

    postCreateInit() {
        if (this._dataObject.layoutManager) {
            this._dataObject.layoutManager.registerModule('groupBy', LayoutGroupByModule, {
                baseline: this.groupBy
            })
        }

        if (this._setupOptions?.setupOptions?.skipLoad) {
            window.requestAnimationFrame(() => {
                this._dataObject.load();
            });
        }
        this._trackLayout = true;
    }

    async load(pParams: any) {
        if (this.isLoading) { return; }
        this.data.splice(0, this.data.length);
        if (!this._groupBy[0]) {
            const data = await this._originalLoad.call(this._dataObject, pParams);
            data.forEach(item => this.data.push(item));
            this._update();
            return data;
        }

        this._dataObject.state.isLoading = true;
        console.log('loading group')

        const options = { ...this._dataObject.recordSource.getOptions() };
        const fields = [];

        let groupOrder = 1;
        this._groupBy[0].forEach(group => {
            const field = { ...this._dataObject.getFields(group.name) };

            if (group.groupByAggregate) {
                field.aggregate = group.groupByAggregate;
            } else {
                field.groupByOrder = groupOrder++;
            }

            fields.push(field);
        });

        options.fields = fields;
        options.fields.push(this._getDetailsCountField());

        const response = <any[]>await this._dataObject.dataHandler.request('retrieve', options);

        let expandedGroups: any;
        if (this._storage && Object.keys(this._storage).length > 0) {
            expandedGroups = []
            Object.entries(this._storage).forEach(([key, group]) => {
                if (!group.item.o_expanded) { return; }
                expandedGroups.push(key);
            });
        }

        this._storage = {};
        const data = this._dataObject.storage.setItems(response, true);

        data.forEach((item: any) => {
            item.o_groupKey = JSON.stringify(this.getGroupByKey(item, 0));
            item.o_groupHeaderRow = true;
            item.o_level = 0;
            this._storage[item.o_groupKey] = { item: item, details: [] };
        })

        // this._dataObject['_data'].splice(0, this._dataObject['_data'].length, ...data)
        this.data.splice(0, this.data.length, ...data)

        // this._dataObject.recordSource.loadRowCounts(null).then(async () => {
        //     if (this._dataObject.rowCount <= this._dataObject.data.length) {
        //         const detailsCount = await this._getDirectDetailsCount(this._dataObject.recordSource.getOptions());
        //         this._dataObject.rowCount = detailsCount.total;
        //     }
        // });

        this._dataObject.state.isLoading = false;
        this._update();
        this._dataObject.emit('DataLoaded', this._dataObject.data);

        // expandedGroups.forEach((key: string) => {
        //     console.log(key);
        //     if (this._storage[key]) {
        //         this.loadGroup(this._storage[key].item, null, true);
        //     }
        // });
    }

    async loadGroup(group: any, pParams: any, skipCheck = false) {
        if (this.isLoading && !skipCheck) { return; }

        if (!group.o_groupKey) {
            console.warn('Not a group: ', group);
            return;
        }

        if (group.o_expanded) { return; }
        group.o_loading = true;
        this.isLoading = true;

        let dataToAdd: any[];
        if (this._storage[group.o_groupKey]?.details?.length > 0) {
            const pushItem = (item: any) => {
                dataToAdd.push(item);
                if (item.o_expanded && this._storage[item.o_groupKey]?.details?.length > 0) {
                    this._storage[item.o_groupKey]?.details.forEach((detail: any) => pushItem(detail));
                }
            };

            dataToAdd = [];
            this._storage[group.o_groupKey]?.details.forEach((detail: any) => pushItem(detail));
        } else {
            group.o_loading = true;
            dataToAdd = await this._retrieveGroupDetails(group, pParams);
            delete group.o_loading;
        }

        const index = this._dataObject.data.findIndex((x: any) => (<any>x).index === group.index);

        group.o_expanded = true;
        if (this._postExpandProcessHandler) {
            await this._postExpandProcessHandler(index, dataToAdd.length);
        }

        // this._dataObject['_data'].splice(index + 1, 0, ...dataToAdd);
        this.data.splice(index + 1, 0, ...dataToAdd);
        this._indexFix(index + 1);
        this.isLoading = false;
        group.o_loading = false;
        this._update();
    }

    private async _retrieveGroupDetails(parentGroup: any, pParams: any, setLoading = true) {
        if (!pParams) { pParams = {}; }
        const options = { ...this._dataObject.recordSource.getOptions(), ...pParams };

        this.isLoading = true;
        if (options.masterDetailString) {
            options.masterDetailString = `(${options.masterDetailString}) AND (${this.buildWhereClauseForGroup(parentGroup)})`;
        } else {
            options.masterDetailString = this.buildWhereClauseForGroup(parentGroup);
        }

        const notLastLevel = this._groupBy.length - 1 > parentGroup.o_level;
        if (notLastLevel) {
            const fields = [];
            for (let i = 0; i <= parentGroup.o_level + 1; i++) {
                const groupLevel = this._groupBy[i];
                let groupOrder = 1;
                groupLevel.forEach(definition => {
                    const field = { ...this._dataObject.getFields(definition.name) };
                    if (definition.groupByAggregate) {
                        field.aggregate = definition.groupByAggregate;
                    } else {
                        field.groupByOrder = groupOrder++;
                    }
                    fields.push(field);
                });
            }

            options.fields = fields;
            options.fields.push(this._getDetailsCountField());
        }

        if (setLoading) {
            parentGroup.o_loading = true;
        }
        const response = <any[]>await this._dataObject.dataHandler.request('retrieve', options);
        if (setLoading) {
            delete parentGroup.o_loading;
        }

        if (!notLastLevel) {
            parentGroup.o_detailsDirectCount = parentGroup.o_detailsCount;
        }

        if (parentGroup.o_detailsDirectCount == null) {
            const detailsCount = await this._getDirectDetailsCount(options);
            parentGroup.o_detailsDirectCount = detailsCount.total;
        }

        // TODO: Dataobject no longer has seperate array for data display. Need to modify storage directly now.
        const data = this._dataObject.storage.setItems(response);
        // Really hacky way just for now, need to refactor logic on where the serverside groups are stored
        // this._dataObject.data.splice(this._dataObject.data.length - data.length, this._dataObject.data.length);

        const prevLoadedDetails = this._storage[parentGroup.o_groupKey].details.length;
        data.forEach((item: any, detailIndex: number) => {
            item.o_level = parentGroup.o_level + 1;
            item.o_detailIndex = prevLoadedDetails + detailIndex;
            if (notLastLevel) {
                item.o_groupKey = JSON.stringify(this.getGroupByKey(item, item.o_level));
                item.o_groupHeaderRow = true;
                this._storage[item.o_groupKey] = { item: item, details: [] };
            }

            if (data.length + prevLoadedDetails < parentGroup.o_detailsDirectCount && detailIndex === data.length - 1) {
                if (this._lastDetailFormatFunction) {
                    const skip = prevLoadedDetails + detailIndex + 1;
                    this._lastDetailFormatFunction(item, parentGroup, skip);
                }
            }

            this._storage[parentGroup.o_groupKey].details.push(item);
        });
        this.isLoading = false;
        return data;
    }

    async collapseDetails(item: any, index?: number) {
        if (!item) {
            item = this._dataObject.current;
        }

        if (!item.o_expanded) { return; }

        if (index == null) {
            index = this._getDataIndex(item);
        }

        item.o_loading = true;

        const currentLevel = item.o_level;
        let lastIndex: number;
        for (let i = index + 1; i < this._dataObject.data.length; i++) {
            const row = this._dataObject.data[i];

            if (row['o_level'] <= currentLevel) {
                lastIndex = i - 1;
                break;
            }
        }

        if (lastIndex == null) { lastIndex = this._dataObject.data.length - 1; }

        item['o_expanded'] = false;
        if (this._postCollapseProcessHandler) {
            await this._postCollapseProcessHandler(index, lastIndex - index);
        }

        // this._dataObject['_data'].splice(index + 1, lastIndex - index);
        this.data.splice(index + 1, lastIndex - index);
        this._indexFix(index + 1);
        this._update();
        item.o_loading = false;
    }

    getDetails(pItem: any) {
        if (pItem == null) {
            pItem = this._dataObject.current;
        }

        return this._storage[pItem.o_groupKey]?.details
    }

    private buildWhereClauseForGroup(group: any) {
        const groups = JSON.parse(group.o_groupKey).flatMap((x: any) => Object.keys(x));
        const clauses = groups.map((key: string) => {
            const field = this._dataObject.getFields(key);
            if (group[key]) {
                let value = group[key];
                switch (field.type) {
                    case 'string':

                        value = value.replaceAll(`'`, `''`);
                    case 'datetime':
                    case 'date':
                        return `${key}='${value}'`;
                    default:
                        return `${key}=${value}`;
                }
            } else {
                return `${key} IS NULL`;
            }
        });
        return clauses.join(' AND ');
    }

    private _getDetailsCountField() {
        return {
            name: '*',
            alias: 'o_detailsCount',
            aggregate: 'COUNT'
        };
    }

    expandAll() {
        const promises = [];

        const dataCopy = [...this._dataObject.data];
        let index = 0;
        while (index < dataCopy.length) {
            const item: any = dataCopy[index];
            if (item.o_groupKey && !item.o_expanded) {
                promises.push(this.loadGroup(item, null, true));
            }
            index++;
        }

        return Promise.all(promises);
    }

    collapseAll() {
        const collapsedRows = this._dataObject.data.reduce((arr: any[], item: any) => {
            if (item.o_expanded) {
                item.o_expanded = false;
            }
            if (item.o_level === 0) {
                arr.push(item);
            }

            return arr;
        }, []) as any[];

        // this._dataObject['_data'].splice(0, this._dataObject['_data'].length);
        // this._dataObject['_data'].splice(0, 0, ...collapsedRows);
        this.data.splice(0, this.data.length);
        this.data.splice(0, 0, ...collapsedRows);
        this._update();
    }

    private async _getDirectDetailsCount(pParams: any) {
        const options = { ...pParams };
        options.skip = 0;
        options.maxRecords = null;

        const response = await this._dataObject.dataHandler.rowCount(pParams);
        return response;
    }

    setLastDetailFormatFunction(func: LastDetailFormatFunction) { this._lastDetailFormatFunction = func; }
    private _indexFix(start: number) {
        return
        for (let i = start; i < this._dataObject.data.length; i++) {
            this._dataObject.data[i].index = i;
        }
    }
}

export interface GroupByExtendedDataItem {
    o_expanded?: boolean;
    o_groupKey?: string;
    o_groupHeaderRow?: boolean;
    o_level?: number;
    o_loading?: boolean;
    o_detailsDirectCount?: number;
}

export interface GroupByDefinition {
    name: string;
    groupByOrder?: number;
    groupByAggregate?: GroupByAggregates;
    groupByAggregateFn?: (data: any[]) => any;
}

export enum GroupByAggregates {
    Avg = 'AVG',
    Count_Distinct = 'COUNT_DISTINCT',
    Count = 'COUNT',
    Sum = 'SUM',
    Max = 'MAX',
    Min = 'MIN',
    Custom = 'CUSTOM'
};

type LastDetailFormatFunction = (item: any, parentGroup: any, skip: number) => any;

interface GroupStorageItem {
    item: GroupByExtendedDataItem,
    details: GroupByExtendedDataItem[]
}